@use "sass:color";

// Grid colors
$grid-days: #009688;

.grid-days-5 {
    background-color: lighten($grid-days, 30%)  !important;
}

.grid-days-4 {
    background-color: lighten($grid-days, 45%)  !important;
}

.grid-days-3 {
    background-color: lighten($grid-days, 60%)  !important;
}

.grid-days-2 {
    background-color: lighten($grid-days, 50%)  !important;
}

.grid-days-1 {
    background-color: lighten($grid-days, 65%) !important;
}

.grid-days-0 {
    background-color: lighten($grid-days, 100%)  !important;
}
