@import 'colors';
@import '../sass/mixins/mixins';

$dash-box-min-width: 150px;
$small-gutter-padding: 15px 10px;

@media screen and (min-width: 992px) {
    .lg\:px-12 {
        padding-left: 9rem !important;
        padding-right: 9rem !important;
    }
}

.dashboard-ss-box-title {
    @include flex();
    @include flex-justify-between();
    height: 100%;
    min-width: $dash-box-min-width;
    margin-bottom: 1em;
    opacity: 1;

    > h6 {
        font-size: 20px;
        margin: 0 0 2px;
    }

    > i {
        font-size: 24px;
    }
}
.dashboard-ss-box {
    @include flex();
    @include flex-justify-between();
    height: 100%;
    min-width: $dash-box-min-width;

    .overview-info {
        > h6 {
            margin: 0 0 2px;
        }
        > h1 {
            margin: 0;
        }
    }
}

@media screen and (min-width: 576px) {
    .p-d-sm-table {
        display: table-cell !important;
    }
}

@media screen and (max-width: 576px) {
    .layout-content {
        padding: 0px;
        > .card {
            padding: $small-gutter-padding;

            .p-panel {
                padding: $small-gutter-padding;
            }
        }

        > .card {
            padding: $small-gutter-padding;

            .p-panel {
                padding: 0;
            }
            .p-panel-content {
                padding: 0;
            }
        }
    }
}

.si-custom-scroll {
    /* Foreground, Background */
    scrollbar-color: var(--primary-color) var(--surface-b);

    &::-webkit-scrollbar {
        width: 10px; /* Mostly for vertical scrollbars */
        height: 10px; /* Mostly for horizontal scrollbars */
    }

    &::-webkit-scrollbar-thumb {
        /* Foreground */
        background: var(--primary-color);
    }
    &::-webkit-scrollbar-track {
        /* Background */
        background: var(--surface-b);
    }
}

.si-badgeselector-treepanel {
    width: 100%;
    max-height: 450px;
}

table {
    > tbody {
        > tr.row-expanded:hover {
            > td:hover {
                background-color: white;
            }
        }
    }
}
